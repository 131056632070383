<template>
  <div>
    <nav-bar></nav-bar>
    <div class="banner">
      <div class="content wow bounceIn">
        <div class="title_CN">企业发展前景 <span>DEVELOPMENT</span></div>
        <div class="title_EN">Enterprise Development Prospects</div>
        <div class="describe">
          郑州约克计算机技术有限公司成立于2007年9月，是一家集软件开发，硬件开发，<br>销售服务于一体的多元化软硬件开发企业。<br>目前公司主营软件开发，系统集成，物联网开发，传感器定制等。
        </div>
        <div class="tontact pointer" @click="contact">联系我们</div>
      </div>
    </div>
    <!-- 企业简介 -->
    <section class="introduction">
      <div class="content wow fadeInUpBig">
        <div class="title_EN">Company Profile</div>
        <div class="title_CN">企业简介</div>
        <div class="introduction_text">
          郑州约克计算机技术有限公司成立于2007年9月，是一家集软件开发，硬件开发，销售服务于一体的多元化软硬件开发企业。目前公司主营软件开发，系统集成，物联网开发，传感器定制等。
          <div>
            我们的宗旨是为企业提供整体软件解决方案，为企业定制适合自己的企业管理软件。
          </div>
        </div>
        <div></div>
      </div>
    </section>
    <!-- 企业项目 -->
    <section class="project">
      <div class="title">
        <div class=" wow bounceInLeft">Enterprise Project</div>
        <div class=" wow bounceInRight">企业项目</div>
      </div>
      <div class="flex justify-between group">
        <div class="item wow bounceInDown">
          <img src="../assets/images/overview/project_icno2.png" alt="降低投资" />
          <div class="itemTitle">降低投资</div>
          <div class="itemDescribe">
            降低企业管理软件的开发周期，降低开发成本
          </div>
        </div>
        <div class="item wow bounceInUp">
          <img src="../assets/images/overview/project_icno1.png" alt="创造收入" />
          <div class="itemTitle">创造收入</div>
          <div class="itemDescribe">
            尽快让项目上线，解决企业痛点，尽快创造收益
          </div>
        </div>
        <div class="item wow bounceInDown">
          <img src="../assets/images/overview/project_icno3.png" alt="增加企业效益" />
          <div class="itemTitle">增加企业效益</div>
          <div class="itemDescribe">
            做最适合企业的软件，使得企业在软件的帮助下增加效益
          </div>
        </div>
      </div>
    </section>
    <!-- 企业文化 -->
    <section class="culture">
      <div class="title">
        <div class=" wow bounceInLeft">Corporate Culture</div>
        <div class=" wow bounceInRight">企业文化</div>
      </div>
      <div class="content flex">
        <img src="../assets/images/overview/culture.png" alt="" />
        <div class="flex-sub">
          <div class="flex item">
            <img src="../assets/images/overview/1.png" alt="" />
            <div>经营理念：精益求精、诚信服务</div>
          </div>
          <div class="flex item">
            <img src="../assets/images/overview/2.png" alt="" />
            <div>奋斗目标：做最适合的企业软件解决方案供应商</div>
          </div>
          <div class="flex item">
            <img src="../assets/images/overview/3.png" alt="" />
            <div>企业文化：简单做人，高效做事，鼓励创新，诚信服务</div>
          </div>
          <div class="flex item">
            <img src="../assets/images/overview/4.png" alt="" />
            <div>远景目标：为所有员工提供一个发展和利益共享的平台</div>
          </div>
        </div>
      </div>
    </section>
    <!-- 联系我们 -->
    <section class="contact">
      <div class="title">
        <div class=" wow bounceInLeft">企业如何快速开发一套适合自己业务的软件？</div>
        <div class=" wow bounceInRight">专业资深的企业为您解决您的烦恼，为您提供最合适的解决方案</div>
      </div>
      <div class="contact_button pointer wow bounceInUp" @click="contact">联系我们</div>
    </section>
    <!-- 合作伙伴 -->
    <!--
    <section class="partner culture">
      <div class="title">
        <div class=" wow bounceInLeft">Cooperative Partner</div>
        <div class=" wow bounceInRight">合作伙伴</div>
      </div>
      <div class="flex justify-between main">
        <div class="trademark wow slideInLeft pointer">
          <img src="../assets/images/overview/trademark1.png" alt="" title="河南镜像文化传播有限公司">
        </div>
        <div class="trademark wow slideInRight pointer" @click="milun">
          <img src="../assets/images/overview/trademark2.png" alt="河南米伦科技有限公司" title="河南米伦科技有限公司">
        </div>
      </div>
    </section>
    -->
    <footbar></footbar>
  </div>
</template>
<script>
import NavBar from "@/components/nav/index";
import footbar from "@/components/footbar/index";
import { WOW } from "wowjs";
export default {
  name: "overview",
  components: { NavBar, footbar },
  mounted(){
    this.$nextTick(() => {
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
  methods:{
    contact(){
      this.$router.push("/about");
    },
    milun(){
      window.open('http://www.hnmilun.com/')
    }
  }
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 737px;
  background-color: transparent;
  background-image: url("../assets/images/banner/summary.png");
  /* background-size: 100% 100%; */
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: table;
}
.banner .content {
  width: 1200px;
    height: 100%;
    position: relative;
    z-index: 1;
    margin: 0 auto;
}
.banner .content .title_CN {
  font-size: 58px;
  font-weight: 500;
  color: #02b2b5;
  position: relative;
    margin-top: 200px;
}
.banner .content .title_CN span {
  position: absolute;
  font-size: 59px;
  font-weight: bold;
  color: #3088a3;
  opacity: 0.21;
  top: -22px;
  left: 0;
}
.banner .content .title_EN {
  font-size: 21px;
  font-weight: 500;
  color: #02b2b5;
}
.banner .content .describe {
  font-size: 20px;
  font-weight: 400;
  color: #378ab3;
  margin: 20px 0 40px 0;
}
.tontact {
  width: 145px;
  height: 39px;
  border: 1px solid #02b2b5;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #02b2b5;
  text-align: center;
  line-height: 39px;
}
.introduction {
  max-width: 1920px;
  margin: 0 auto;
  background: url("../assets/images/overview/bg.png") no-repeat 100% 100%;
  height: 564px;
  padding-top: 130px;
  position: relative;
}
.introduction .content {
  width: 1200px;
  position: relative;
  z-index: 9;
  margin: 0 auto;
  top: -40px;
}
.introduction .content .title_EN {
  font-size: 26px;
  font-weight: 400;
  color: #02b2b5;
}
.introduction .content .title_CN {
  font-size: 34px;
  font-weight: 500;
  color: #02b2b5;
}
.introduction .content .introduction_text {
  width: 560px;
  font-size: 14px;
  font-weight: 500;
  color: #999999;
  line-height: 30px;
  margin-top: 40px;
}
.introduction .content .introduction_text > div {
  margin-top: 30px;
}
.project {
  max-width: 1920px;
  margin: 0 auto;
  background: url("../assets/images/overview/bg1.png") no-repeat 100% 100%;
  height: 925px;
  position: relative;
}
section .title {
  padding-top: 110px;
  text-align: center;
  color: #ffffff;
}
section .title > div:nth-child(1) {
  font-size: 26px;
  font-weight: 400;
}
section .title > div:nth-child(2) {
  font-size: 34px;
  font-weight: 500;
}
.project .group {
  width: 1200px;
  margin: 129px auto 0;
}
.project .group .item {
  width: 380px;
  height: 439px;
  background: #ffffff;
  border-radius: 15px;
  text-align: center;
}
.project .group .item img {
  width: 127px;
  height: 120px;
  display: block;
  margin: 86px auto 0;
}
.project .group .item .itemTitle {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin-top: 49px;
}
.project .group .item .itemDescribe {
  width: 272px;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  margin: 46px auto 0;
}
.culture .title {
  color: #02b2b5;
}
.culture .content {
  width: 1200px;
  margin: 82px auto 130px;
}
.culture .content > img {
  width: 446px;
  height: 413px;
}
.culture .content > .flex-sub {
  margin-left: 14px;
}
.culture .content > .flex-sub > .item {
  line-height: 63px;
  padding: 20px 0 11px 0;
  border-bottom: 1px dashed #b5b5b5;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.culture .content > .flex-sub > .item div {
  margin-left: 36px;
}
.culture .content > .flex-sub > .item img {
  width: 70px;
  height: 63px;
  margin-left: 22px;
}
.contact {
  max-width: 1920px;
  margin: 0 auto;
  background: url("../assets/images/overview/contact.png") no-repeat 100% 100%;
  height: 343px;
  position: relative;
}
.contact .title {
  padding-top: 74px;
}
.contact .title > div:nth-child(1) {
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
}
.contact .title > div:nth-child(2) {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 23px;
}
.contact .contact_button {
  margin: 69px auto 0;
  width: 188px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #ffffff;
  border-radius: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #02b2b5;
  text-align: center;
}
.partner .trademark {
  width: 549px;
  height: 340px;
  background: #eeeeee;
  margin-top: 73px;
  margin-bottom: 167px;
  display: flex;
}
.partner .trademark img{
  margin:auto
}
.partner .trademark:nth-child(1) img{
  width: 448px;
  height: 247px;
}
.partner .trademark:nth-child(2) img{
  width: 440px;
  height: 121px;
}
</style>
